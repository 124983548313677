<template>
    <div 
        class="flex items-center cursor-pointer " 
        @click="clickHandler(clickHandlerParam)">
        <template v-if="stringView">
            <div class="pr-2">
                <a-avatar 
                    :size="26" 
                    icon="fi-rr-users-alt"
                    flaticon
                    :key="workgroupLogoPath"
                    :src="workgroupLogoPath" />
            </div>
            <span class="blue_color group_name truncate">
                {{ stringView }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        },
        openHandler: {
            type: Function,
            default: () => {}
        },
        model: {
            type: String,
            required: true
        },
        tableType: {
            type: String
        },
        column: {
            type: Object
        },
    },
    computed: {
        stringView() {
            if(this.column.key === 'project') {
                return this.record.project?.name || null
            }
            if(this.column.key === 'workgroup') {
                return this.record.workgroup?.name || null
            }
            if(this.column.key === 'organization') {
                return this.record.organization?.name || null
            }
            return this.record.name
        },
        isWorkgroupAndProject() {
            return this.model === 'workgroups.WorkgroupModel'
        },
        isModeration() {
            return this.tableType === 'moderation'
        },
        isName() {
            return this.column.key === 'name'
        },
        clickHandler() {

            if(this.column.key === 'project' && this.record?.project?.id) {
                this.$router.replace({
                    query: { viewGroup: this.record.project.id }
                })
            }
            if(this.column.key === 'workgroup' && this.record?.workgroup?.id) {
                this.$router.replace({
                    query: { viewGroup: this.record.workgroup.id }
                })
            }
            if((this.isWorkgroupAndProject && this.isName) ||
                this.isModeration)
                return this.openHandler        
            return () => {}
        },
        clickHandlerParam() {
            if(this.isWorkgroupAndProject && this.isName)
                return this.record.id
            if (this.isModeration)
                return this.record
            return null
        },
        workgroupLogoPath() {
            if(this.column.key === 'organization') {
                return this.record.organization?.logo || ''
            }
            if(this.column.key === 'project')
                return this.record.project?.workgroup_logo?.path || ''
            if(this.column.key === 'workgroup')
                return this.record.workgroup?.workgroup_logo?.path || ''
            return this.record?.workgroup_logo?.path || ''

        }

    }
}
</script>